<template>
    <v-app-bar color="white" light height="92px" >
        <div class="d-none d-lg-flex">
            <v-btn @click="emitChanges" icon>
                <v-icon>mdi-menu</v-icon>
            </v-btn>
        </div>
      <div class="d-flex d-lg-none hide-for-mobile">
        <v-btn @click="emitChangesSidebarForTab" icon>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-col></v-col>
      </div>
        <div class="d-flex d-lg-none hide-for-tab">
            <v-btn @click="emitChangesSidebar" icon>
                <v-icon>mdi-menu</v-icon>
            </v-btn>
            <v-col></v-col>
        </div>
        <v-toolbar-title>
            <v-img class="customer-logo" src="../../../../../src/assets/nbs.png"></v-img>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn color="primary darken-2" dark
               @click="emitModal"  class="hidden-md-and-up">
            <v-icon dark>mdi-account</v-icon>
        </v-btn>

        <v-list dense class="hidden-sm-and-down text-left">
            <v-list-item-group>
                <v-list-item>
                    <v-list-item-content>
                        <tbody class="subtitle-1">
                        <tr>
                            <td class="font-weight-bold">{{$t('msg.id')}}</td>
                            <td class="pl-2">:</td>
                            <td class="px-5">{{$store.state.home.user.username}}</td>
                        </tr>
                        <tr v-if="isLoanOfficer">
                            <td class="font-weight-bold">{{$t('msg.branch')}}</td>
                            <td class="pl-2">:</td>
                            <td class="px-5">{{$store.state.home.user.branch}}</td>
                        </tr>
                        <tr v-if="isHqOfficer">
                            <td class="font-weight-bold">{{$t('msg.organisation')}}</td>
                            <td class="pl-2">:</td>
                            <td class="px-5">{{$store.state.home.user.organizationName}}</td>
                        </tr>
                        </tbody>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
       <v-btn @click="logout" color="primary darken-2" class="mt-2 hidden-sm-and-down text-left">Logout</v-btn>
    </v-app-bar>
</template>

<script>
import {EventBus} from '../../../../_helpers'
import {homeService} from '../../../../services'

export default {
        name: "DashboardCoreAppBar",
        data() {
            return {}
        },
        methods: {
            emitChanges: function () {
                EventBus.$emit('Clicked')
            },
            emitChangesSidebar: function () {
                EventBus.$emit('Sidebar')
            },
            emitChangesSidebarForTab: function () {
              EventBus.$emit('SidebarForTab')
            },
            logout: function () {
                homeService.logout().then(
                    EventBus.$emit('logout')
                ).catch(function () {
                    console.log("could not log out")
                })
            },
            emitModal: function() {
                EventBus.$emit('openModal')
            }
        },
        computed: {
            isLoanOfficer: function () {
                return this.$store.state.home.user.roles.includes('ROLE_LOAN_OFFICER')
            },
            isSupervisor: function () {
                return this.$store.state.home.user.roles.includes('ROLE_SUPERVISOR')
            },
            isHqOfficer: function () {
                return this.$store.state.home.user.roles.includes('ROLE_HQ_OFFICER')
            }
        }
    }
</script>

<style scoped>

.customer-logo {
  width: 120px;
}

@media only screen and (min-width: 1000px) and (max-width: 1300px) {
  .hide-for-tab {
    display: none !important;
  }
}
@media only screen and (max-width: 999px) {
  .hide-for-mobile {
    display: none !important;
  }
  .customer-logo {
    width: 120px;
  }
}
</style>
