<template>
    <v-app v-if="loadedBasicInfo">
        <dashboard-core-drawer/>
        <dashboard-core-view class="content"/>
        <v-dialog v-model="idleTimeOver" persistent max-width="290">
            <v-card>
                <v-card-title class="title-timeout">{{$t('msg.session_expire_heading')}}</v-card-title>
                <v-card-text>{{$t('msg.session_expire_message_1')}} {{logOutTimeRemaining}}
                    {{$t('msg.session_expire_message_2')}}
                </v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="green darken-1" text @click=triggerLogout>{{$t('logout_now')}}</v-btn>
                    <v-btn color="green darken-1" text @click=triggerRefresh>{{$t('continue')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import DashboardCoreView from "./component/core/View"
    import DashboardCoreDrawer from "./component/core/Drawer"
    import {homeService} from "../../services"
    import {EventBus, router} from '../../_helpers'

    export default {
        name: "DashboardIndex",
        components: {
            DashboardCoreDrawer,
            DashboardCoreView
        },
        data() {
            return {
                idleTimeTicker: null,
                idleTimeRemaining: 900,
                idleTimerTickerStartedAt: 0,
                timerDurationPassed: 0,

                logOutTimeTicker: null,
                logOutTimeRemaining: 20,
                logOutTimerTickerStartedAt: 0,
                logOutTimerDurationPassed: 0,

                childComponentApiLoading: false,
                idleTimeOver: false,
                loadedBasicInfo: false
            }
        },
        methods: {
            isLocalStorageAllowed: function () {
                return typeof (Storage) !== 'undefined'
            },
            fetchInitializationData: function () {
                this.childComponentApiLoading = true
                homeService.fetchHome().then(response => {
                    if (this.isLocalStorageAllowed()) {
                        localStorage.setItem('_gfi_package_dashboard_sid', response.dataResponse.state.sidIdentifier)
                        this.$store.dispatch('home/setSidIdentifier', response.dataResponse.state.sidIdentifier)
                    } else {
                        console.log('Nedas Not supported')
                    }
                    this.$store.dispatch('home/setUserState', {
                        authenticated: response.dataResponse.state.authenticated,
                        fullName: response.dataResponse.state.fullName,
                        username: response.dataResponse.state.username,
                        language: response.dataResponse.state.language,
                        branch: response.dataResponse.state.branch,
                        roles: response.dataResponse.state.roles,
                        products: response.dataResponse.state.products,
                        isExportButtonEnabled : response.dataResponse.state.exportButtonEnabled,
                        theme: response.dataResponse.state.themeDTO,
                        enableEmail: response.dataResponse.state.enableEmail,
                        enableSms: response.dataResponse.state.enableSms,
                        enableProfile: response.dataResponse.state.enableProfile,
                        region: response.dataResponse.state.region,
                        organizationName: response.dataResponse.state.organizationName
                    })
                    // this.$vuetify.theme.themes.primary = this.$store.home.state.theme.primary
                    if (null !== response.dataResponse.state.themeDTO) {
                        this.$vuetify.theme.themes.dark = this.getTheme
                        this.$vuetify.theme.themes.light = this.getTheme
                    }

                    this.childComponentApiLoading = false
                    this.loadedBasicInfo = true
                }).catch(function (error) {
                    console.log(error)
                    if (error.response && error.response.status === 403) {
                        //console.log('loggin response ' + error)
                        EventBus.$emit('unauthorized')
                    }
                })
            },
            startIdleTicker: function () {
                console.log('idle timer start')
                this.idleTimeTicker = setInterval(() => {
                    if (this.isUserAuthenticated && (this.idleTimeRemaining === 0 || this.idleTimeRemaining < 0)) {
                        this.idleTimeOver = true
                        this.startLogoutTicker()
                        clearInterval(this.idleTimeTicker)
                    } else {
                        if (!this.childComponentApiLoading) {
                            this.idleTimeRemaining = this.idleTimeRemaining - 1
                            this.timerDurationPassed = this.timerDurationPassed + 1

                            let displacement = (Math.floor((Date.now() - this.idleTimerTickerStartedAt) / 1000)) - this.timerDurationPassed
                            if (displacement > 2) {
                                this.idleTimeRemaining = this.idleTimeRemaining - displacement
                                this.timerDurationPassed = this.timerDurationPassed + displacement
                            }
                        }
                    }
                }, 1000)
                this.idleTimerTickerStartedAt = Date.now()
            },
            startLogoutTicker: function () {
                console.log('logout timer start')
                this.logOutTimeTicker = setInterval(() => {
                    if (this.isUserAuthenticated && (this.logOutTimeRemaining === 1 || this.logOutTimeRemaining < 1)) {
                        clearInterval(this.logOutTimeTicker)
                        this.triggerLogout()
                    } else {
                        this.logOutTimeRemaining = this.logOutTimeRemaining - 1
                        this.logOutTimerDurationPassed = this.logOutTimerDurationPassed + 1

                        let displacement = (Math.floor((Date.now() - this.logOutTimerTickerStartedAt) / 1000)) - this.logOutTimerDurationPassed
                        if (displacement > 2) {
                            this.logOutTimeRemaining = this.logOutTimeRemaining - displacement
                            this.logOutTimerDurationPassed = this.logOutTimerDurationPassed + displacement
                        }
                    }
                }, 1000)
                this.logOutTimerTickerStartedAt = Date.now()
            },
            resetIdleTimer: function () {
                console.log('timer reset')
                this.idleTimerTickerStartedAt = Date.now()
                this.idleTimeRemaining = 900
            },
            triggerLogout: function () {
                clearInterval(this.logOutTimeTicker)
                EventBus.$emit('logout')
            },
            triggerRefresh: function () {
                window.location.reload()
                return false
            },
            logoutNav: function () {
                router.push('/logout').catch((err) => {
                    console.log(err)
                });
                EventBus.$off('logout', this.logoutNav)
            },
            unauthorizedNav: function () {
                router.push('/unauthorized').catch((err) => {
                    console.log(err)
                });
            },
            onStorageEvent: function (storageEvent) {
                if (!this.$store.getters['home/sidIdentifier']) {
                    return
                }
                if (storageEvent.key === '_gfi_package_dashboard_sid' && this.$store.getters['home/sidIdentifier'] !== storageEvent.newValue) {
                    console.log('trigger invalid')
                    router.push('/invalidPage').catch((err) => {
                        console.log(err)
                    });
                }
            }
        },
        computed: {
            isUserAuthenticated() {
                return this.$store.state.home.authenticated
            },
            getTheme() {
                return this.$store.state.home.theme
            }
        },
        watch: {
          getTheme() {
              this.$vuetify.theme.themes.dark = this.$store.state.theme
          }
        },
        created() {
            EventBus.$on('logout', this.logoutNav)
            EventBus.$on('unauthorized', this.unauthorizedNav)
        },
        mounted() {
            window.addEventListener('storage', this.onStorageEvent)
            this.fetchInitializationData()
            this.startIdleTicker()
            window.addEventListener('mouseup', this.resetIdleTimer)
            window.addEventListener('onmousedown', this.resetIdleTimer)
            window.addEventListener('ontouchstart', this.resetIdleTimer)
            window.addEventListener('onclick', this.resetIdleTimer)
            window.addEventListener('onscroll', this.resetIdleTimer)
            window.addEventListener('onkeypress', this.resetIdleTimer)
        },
        beforeDestroy() {
            clearInterval(this.idleTimeTicker)
            EventBus.$off('logout', this.logoutNav)
            EventBus.$off('unauthorized', this.unauthorizedNav)
        }
    }
</script>

<style scoped>
.title-timeout {
  font-size: 19px !important;
  font-weight: 500 !important;
}
</style>
