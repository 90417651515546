<template>
    <div>
        <div class="content">
            <Header/>
            <v-container>
                <v-layout align-center justify-center>
                    <v-flex lg12 md12 sm12 xs12>
                        <v-row justify-center>
                            <v-card
                                    class="mx-auto"
                                    outlined
                                    shaped
                                    max-width="500"
                                    elevation="10"
                            >
                                <v-row class="d-flex justify-sm-center">
                                    <v-col sm="12" md="12" lg="12">
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <div class="overline mb-4">
                                                  {{ $t('msg.invalid_text_1') }}
                                                </div>
                                                <v-list-item-content class="headline mb-2 font-weight-light">
                                                  {{ $t('msg.invalid_expired_text') }}
                                                </v-list-item-content>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <!--                                    <v-col sm="4" md="4" lg="4">-->
                                    <!--                                        <v-img src="../../../src/assets/error.png" style="width: 222px"></v-img>-->
                                    <!--                                    </v-col>-->
                                </v-row>
                                <router-link to="/" style="text-decoration: none">
                                    <v-card-actions>
                                        <v-btn
                                                outlined
                                                text
                                                color="primary"
                                                x-large
                                                block
                                        >
                                          {{ $t('msg.home') }}
                                            <v-icon class="ml-3">mdi-home</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </router-link>
                            </v-card>
                        </v-row>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Header from "../login/Header";
    import Footer from "../login/Footer";

    export default {
        name: "Unauthorized",
        components: {Header, Footer}
    }
</script>
