<template>
    <v-content>
        <div class="content">
            <dashboard-core-app-bar/>
            <v-snackbar
                    :value="dialog"
                    :timeout="timeout"
                    absolute
                    centered
                    color="white"
                    elevation="5"
                    shaped
                    top
                    vertical
            >
                <v-simple-table light>
                    <template v-slot:default>
                        <tbody class="subtitle-1">
                        <tr>
                            <td class="font-weight-bold">{{$t('msg.id')}}</td>
                            <td class="pl-2">:</td>
                            <td class="px-5">{{$store.state.home.user.username}}</td>
                        </tr>
                        <tr v-if="isLoanOfficer">
                            <td class="font-weight-bold">{{$t('msg.branch')}}</td>
                            <td class="pl-2">:</td>
                            <td class="px-5">{{$store.state.home.user.branch}}</td>
                        </tr>
                        <tr v-if="isSupervisor">
                            <td class="font-weight-bold">{{$t('msg.region')}}</td>
                            <td class="pl-2">:</td>
                            <td class="px-5">{{$store.state.home.user.region}}</td>
                        </tr>
                        <tr v-else-if="isHqOfficer">
                            <td class="font-weight-bold">{{$t('msg.organisation')}}</td>
                            <td class="pl-2">:</td>
                            <td class="px-5">{{$store.state.home.user.organizationName}}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-divider></v-divider>
                <template>
                    <div class="d-flex justify-center mt-5">
                        <v-btn
                                @click="dialog = false"
                                class="mr-10"
                                color="primary"
                                small
                        >
                          {{$t('msg.close_label')}}
                        </v-btn>
                        <v-btn
                                @click="emitLogout"
                                color="primary"
                                dark
                                small
                        >
                          {{$t('logout')}}
                        </v-btn>
                    </div>
                </template>
            </v-snackbar>
            <router-view/>
        </div>
        <Footer/>
    </v-content>
</template>

<script>
    import DashboardCoreAppBar from "./AppBar";
    import Footer from "./Footer";
    import {EventBus} from "../../../../_helpers";

    export default {
        name: "DashboardCoreView",
        components: {Footer, DashboardCoreAppBar},
        data() {
            return {
                dialog: false,
                timeout: 0,
            }
        },
        methods: {
            openModal: function () {
                this.dialog = true
            },
            emitLogout: function () {
                EventBus.$emit('logout')
            }
        },
        computed: {
            isLoanOfficer: function () {
                return this.$store.state.home.user.roles.includes('ROLE_LOAN_OFFICER')
            },
            isSupervisor: function () {
                return this.$store.state.home.user.roles.includes('ROLE_SUPERVISOR')
            },
            isHqOfficer: function () {
                return this.$store.state.home.user.roles.includes('ROLE_HQ_OFFICER')
            },
            screenSize: function () {
            return this.$vuetify.breakpoint.name
          }
        },
        created() {
            EventBus.$on('openModal', this.openModal);
        },
        watch: {
          screenSize : function (newValue) {
            if(!(newValue==='xs'||newValue==='sm') && this.dialog) {
              this.dialog=false
            }
          }
      }
    }
</script>
