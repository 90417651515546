<template>
<v-app>
    <Header/>
    <Login/>
    <Footer/>
</v-app>
</template>

<script>
    import Login from "./Login";
    import Header from "./Header";
    import Footer from "./Footer";
    export default {
        name: "Index",
        components: {Login, Header, Footer}
    }
</script>

<style scoped>

</style>
